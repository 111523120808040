import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

import { LoginModel, UserModel } from '@app/models';

@Injectable({
    providedIn: 'root'
})
export class AuthService
{
    private userAuthStatusHasChanged = new EventEmitter<boolean>();
    private endPoint = '/auth';

    constructor(
        private http: HttpClient,
    )
    {
    }

    authStatusHasChanged(authenticated: boolean)
    {
        if (authenticated)
        {
            this.userAuthStatusHasChanged.emit(true);
        }
        else
        {
            this.userAuthStatusHasChanged.emit(false);
        }
    }

    getUser(): Promise<UserModel>
    {
        const url = `${this.endPoint}/me`;

        return this.http
            .get<UserModel>(url)
            .toPromise();
    }

    verify(): Promise<boolean>
    {
        const url = `${this.endPoint}/verify`;

        return this.http
            .get<boolean>(url)
            .toPromise();
    }

    login(credentials: LoginModel): Promise<{ token: string; account: UserModel }>
    {
        const url = `${this.endPoint}/login`;

        return this.http
            .post<{ token: string; account: UserModel }>(url, credentials)
            .toPromise();
    }
}
