import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class StorageService
{
    static async configure(): Promise<void>
    {
        await Preferences.configure({ group: environment.databaseName });
    }

    static async get<T>(key: string): Promise<T>
    {
        const result: GetResult = await Preferences.get({ key });

        return JSON.parse(result.value) as T;
    }

    static async remove(key: string): Promise<void>
    {
        await Preferences.remove({ key });
    }

    static async set(key: string, data: any): Promise<void>
    {
        data = JSON.stringify(data);

        await Preferences.set({ key, value: data });
    }

    static async clear(): Promise<void>
    {
        await Preferences.clear();
    }
}
