import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService
{
    private loading;

    constructor(
        private loadingController: LoadingController,
    )
    {
    }

    async show(message: string = 'Please wait...', duration: number = null): Promise<void>
    {
        if (!this.loading)
        {
            this.loading = await this.loadingController.create({
                message,
                duration,
                animated: true,
                backdropDismiss: false,
                showBackdrop: true,
                keyboardClose: true,
                spinner: 'bubbles',
            });

            await this.loading.present();
        }
    }

    async hide(): Promise<void>
    {
        if (this.loading)
        {
            await this.loading.dismiss();
            this.loading = null;
        }
    }
}
