import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { StoreModel } from '@app/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StoreService
{
    private endPoint = '/stores';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getStores(limit: number, offset: number, searchTerm: string): Observable<StoreModel[]>
    {
        const url = `${this.endPoint}`;
        const params = new HttpParams()
            .set('limit', limit)
            .set('offset', offset)
            .set('searchTerm', searchTerm);

        return this.http
            .get<StoreModel[]>(url, { params });
    }
}
