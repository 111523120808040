import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SurveyModel } from '@app/models';

@Injectable({
    providedIn: 'root'
})
export class SurveyService
{
    private endPoint = '/surveys';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getSurveys(): Promise<SurveyModel[]>
    {
        const url = `${this.endPoint}`;

        return this.http
            .get<SurveyModel[]>(url)
            .toPromise();
    }

    submitSurvey(surveyId: number, storeId: number, answers): Promise<{ status: boolean; message: string }>
    {
        const url = `${this.endPoint}/${surveyId}`;

        const formData = new FormData();
        formData.append('storeId', storeId.toString());

        for (const key in answers)
        {
            if (answers.hasOwnProperty(key))
            {
                const answer = answers[key];

                if (answer.hasOwnProperty('comment') && answer.comment !== null)
                {
                    formData.append(`answers[${key}][comment]`, answer.comment);
                }

                if (answer.hasOwnProperty('photos'))
                {
                    for (const photo of answer.photos)
                    {
                        formData.append(`${key}[]`, photo);
                    }
                }

                if (answer.hasOwnProperty('response') && answer.response !== null)
                {
                    formData.append(`answers[${key}][response]`, answers[key].response);
                }
            }
        }

        return this.http
            .post<{ status: boolean; message: string }>(url, formData)
            .toPromise();
    }
}
