import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserRepository } from '@app/repositories';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor(
        private userRepository: UserRepository,
    )
    {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return from(this.userRepository.getLoggedInUserToken())
            .pipe(
                switchMap((token: string) =>
                {
                    if (!token)
                    {
                        return next.handle(req.clone());
                    }

                    let headers = req.headers;

                    if (!headers.has('Authorization'))
                    {
                        headers = headers.append('Authorization', `Bearer ${token}`);
                    }

                    return next.handle(req.clone({
                        headers,
                    }));
                }),
            );
    }
}
