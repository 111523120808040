import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AlertService, AuthService } from '@app/services';
import { environment } from '@env/environment';
import { UserRepository } from '@app/repositories';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate
{
    private isVerified = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private userRepository: UserRepository,
        private alertService: AlertService,
    )
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean
    {
        if (environment.debug && !environment.verifyAuth) // only for testing purpose
        {
            this.authService.authStatusHasChanged(true);

            return true;
        }

        return this.isTokenValid();
    }

    private async isTokenValid(): Promise<boolean>
    {
        return new Promise(async (resolve, reject) =>
        {
            if (this.isVerified)
            {
                this.authService.authStatusHasChanged(true);

                return resolve(true);
            }

            const token = await this.userRepository.getLoggedInUserToken();

            if (!token)
            {
                this.isVerified = false;
                await this.logoutAndNavigateToWelcomePage(false);

                return reject(false);
            }

            try
            {
                const accessGranted = await this.authService.verify();

                if (accessGranted) // token verified, access granted
                {
                    this.isVerified = true;
                    this.authService.authStatusHasChanged(true);

                    return resolve(true);
                }

                this.isVerified = false;
                // error when verify so redirect to login page
                await this.logoutAndNavigateToWelcomePage();

                return reject(false);
            }
            catch (e)
            {
                this.isVerified = false;
                console.log('Error in canActivate in AuthGuard', e);
                await this.logoutAndNavigateToWelcomePage();

                return reject(false);
            }
        });
    }

    private async logoutAndNavigateToWelcomePage(showMessage = true): Promise<void>
    {
        if (showMessage)
        {
            const message = 'Please re-login.';
            await this.alertService.show(message);
        }

        await this.userRepository.logOut();
        await this.router.navigate(['login']);
    }
}
