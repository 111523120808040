import { Injectable } from '@angular/core';

import { StoreModel } from '@app/models';
import { StorageService } from '@app/services';

@Injectable({
    providedIn: 'root'
})
export class StoreRepository
{
    async selectStore(store: StoreModel): Promise<void>
    {
        await StorageService.set('selected_store', store);
    }

    async getSelectedStore(): Promise<StoreModel>
    {
        return await StorageService.get('selected_store');
    }

    async clearSelectedStore(): Promise<void>
    {
        await StorageService.remove('selected_store');
    }
}
