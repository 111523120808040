import { Injectable } from '@angular/core';

import { SurveyModel } from '@app/models';
import { StorageService } from '@app/services';

@Injectable({
    providedIn: 'root'
})
export class SurveyRepository
{
    async selectSurvey(survey: SurveyModel): Promise<void>
    {
        await StorageService.set('selected_survey', survey);
    }

    async getSelectedSurvey(): Promise<SurveyModel>
    {
        return await StorageService.get('selected_survey');
    }

    async clearSelectedSurvey(): Promise<void>
    {
        await StorageService.remove('selected_survey');
    }
}
