import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService
{
    constructor(
        private toastController: ToastController,
    )
    {
    }

    async show(
        message: string = 'Please try again.',
        duration = 4000,
        header = '',
        position: 'top' | 'bottom' | 'middle' = 'bottom'
    )
    {
        const toast = await this.toastController.create({
            header,
            message,
            duration,
            position,
            animated: true,
            keyboardClose: false,
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                },
            ],
        });

        await toast.present();
    }
}
