import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/guards';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'stores/:storeId',
        loadChildren: () => import('./pages/store/store.module').then(m => m.StorePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'stores/:storeId/survey/:surveyId',
        loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
