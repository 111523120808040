import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { version } from '@env/version';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const headersToApply = [
            { key: 'Accept', value: 'application/json', },
            { key: 'X-App-Version', value: version.currentVersion, },
        ];
        let requestHeaders = req.headers;

        for (const headerToApply of headersToApply)
        {
            if (!requestHeaders.has(headerToApply.key))
            {
                requestHeaders = requestHeaders.append(headerToApply.key, headerToApply.value);
            }
        }

        return next.handle(req.clone({
            headers: requestHeaders,
        }));
    }
}
