import { Injectable } from '@angular/core';

import { UserModel } from '@app/models';
import { StorageService } from '@app/services';

@Injectable({
    providedIn: 'root'
})
export class UserRepository
{
    async login(user: UserModel, token: string): Promise<void>
    {
        await StorageService.set('account', user);
        await StorageService.set('token', token);
    }

    async updateUser(user: UserModel): Promise<void>
    {
        await StorageService.set('account', user);
    }

    async getLoggedInUserToken(): Promise<string>
    {
        return await StorageService.get('token');
    }

    async saveUserPushToken(token: string): Promise<void>
    {
        await StorageService.set('push_token', token);
    }

    async getUserPushToken(): Promise<string>
    {
        return await StorageService.get('push_token');
    }

    async getLoggedInUser(): Promise<UserModel>
    {
        return await StorageService.get('account');
    }

    async logOut(): Promise<void>
    {
        await StorageService.clear();
    }
}
