import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class InitializeAppService
{
    async initializeApp()
    {
        await StorageService.configure();

        if (Capacitor.isNativePlatform())
        {
            await this.initializeNative();
        }
    }

    private async initializeNative()
    {
        await StatusBar.setOverlaysWebView({ overlay: false });
    }
}
